import { Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';

let p = {
	fontSize:'var(--font-kecil)', 
	textAlign:'center',
	background: 'var(--putih)',
	lineHeight: '2em',
	marginBottom: '0',
	color: 'var(--hitam)',
}

const MenuItem = ({imgSrc, text, to}) => {
	return (
		<Col xs="6">
			<NavLink to={to} style={{textDecoration: 'none'}}>
				<div style={{background:'var(--putih)', borderRadius:'20px', overflow:'hidden', marginBottom:'1em'}}>
					<img src={imgSrc} alt={imgSrc} width="100%" />
					<p style={p}>{text}</p>
				</div>
			</NavLink>
		</Col>
	)
}

export default MenuItem;