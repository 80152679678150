import { useContext, useEffect, useState } from 'react';
import { NavContext } from '../../App.js';
import { Container, Row, Col } from 'reactstrap';
import { ContentLink } from '../../components/Content/Content';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

//===dummy task
let regulerTasks = [
	{ id:0, title:'User', checked:true },
	{ id:1, title:'Seller', checked:true },
	{ id:2, title:'Network Builder', checked:true },
	{ id:3, title:'Network Leaders', checked:true },
]
let premiumTasks = [
	{ id:0, title:'User', checked:false },
	{ id:1, title:'Seller', checked:false },
	{ id:2, title:'Network Builder', checked:false },
	{ id:3, title:'Network Leaders', checked:false },
]

const UserReportPerUser = ({history}) => {
	let [ isPremiumTask, setIsPremiumTask ] = useState(false);
	let [ tasks, setTasks ] = useState([])
	let { dispatch } = useContext(NavContext);

	let progressColor = progress => {
		if(progress>=80){
			return 'progress-success'
		}else if(progress>=60){
			return 'progress-warning'
		}else if(progress<=59){
			return 'progress-danger'
		}
	}

	useEffect(()=>{
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
		// eslint-disable-next-line 
	},[])

	let changeTaskType = bool => {
		setIsPremiumTask(bool)
		if(bool){
			setTasks(premiumTasks)
		}else{
			setTasks(regulerTasks)
		}
	}
	ChangeTitle({title:'Digipreneur | (username) Report'})
	return (
		<div style={{background:'var(--putih)'}}>
			<Container style={{paddingTop:'2em'}}>
				<Row>
					<Col xs="6" className='text-center'>
						<div style={{background:'var(--abu-abu2)', borderRadius:'15px'}}>
							<img src="/img/profile/Default.svg" alt="Default.svg" style={{padding:'1em'}} width="100%" />
						</div>
						<p style={{fontSize:'.85em', margin:'0', color:'black', fintWeight:'700'}}>Gede Sangu Gemi</p>
						<p style={{fontSize:'.8em', margin:'0', color:'var(--abu-abu)'}}>member premium</p>
					</Col>
					<Col xs="4">
						<p style={{ margin:'0', color:'var(--abu-abu)'}}>tugas biasa</p>
						<div className={progressColor(25)} style={{margin:'.5em 0', borderRadius:'1em', height:'1.2em', color:'var(--hitam)', textAlign:'center'}}>
							<p>25%</p>
						</div>
						<p style={{ margin:'0', color:'var(--abu-abu)'}}>tugas premium</p>
						<div className={progressColor(90)} style={{margin:'.5em 0', borderRadius:'1em', height:'1.2em', color:'var(--hitam)', textAlign:'center'}}>
							<p>25%</p>
						</div>
					</Col>
				</Row>
			</Container>

			<style>{`
				.task-active{
					border-bottom: solid 2px var(--abu-abu);
				}
			`}</style>
			<div className="clearfix text-center mt-4">
				<p onClick={()=>changeTaskType(false)} className={(!isPremiumTask)?"d-inline-block float-left w-50 task-active fw-normal mb-1":"d-inline-block float-left w-50 fw-normal mb-1"}>Tugas Biasa</p>
				<p onClick={()=>changeTaskType(true)} className={(isPremiumTask)?"d-inline-block float-left w-50 task-active fw-normal mb-1":"d-inline-block float-left w-50 fw-normal mb-1"}>Tugas Premium</p>
			</div>
			<Container>
				<p className="text-center">4 dari 10 tugas selasai</p>
				{tasks.map(task=>{
					return <ContentLink title={task.title} to="#" key={task.id} checked={task.checked} />
				})}
			</Container>

			<button onClick={history.goBack} className="fix-btn-bottom" style={{background:'var(--birutua)'}}>TUTUP</button>
		</div>
	)
}
export default UserReportPerUser;