import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';


const items = [
  {
    id: 1,
    videoSrc: "https://www.youtube.com/embed/60ItHLz5WEA",
    altText: 'image1.jpg',
    title: 'Judul 0'
  },
  {
    id: 2,
    videoSrc: "https://www.youtube.com/embed/1-xGerv5FOk",
    altText: 'image2.jpg',
    title: 'Judul 1'
  },
  {
    id: 3,
    videoSrc: 'https://www.youtube.com/embed/dhYOPzcsbGM',
    altText: 'image3.jpg',
    title: 'Judul 2'
  },
  {
    id: 4,
    videoSrc: 'https://www.youtube.com/embed/hdonNbzHHXE',
    altText: 'image4.jpg',
    title: 'Judul 3'
  },
  {
    id: 5,
    videoSrc: 'https://www.youtube.com/embed/HhjHYkPQ8F0',
    altText: 'image5.jpg',
    title: 'Judul 4'
  }
];


const ImagesCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem key={item.id}>
        <YoutubeEmbed src={item.videoSrc} />
        <CarouselCaption captionText={item.title}/>
      </CarouselItem>
    );
  });

  return (
    <div>
      <div className={props.className}>
        <style>
          {`.video-carousel-indicator{
              bottom: unset;
            }
            .video-carousel-indicator li{
              width: 10px;
              height: 10px;
              background: var(--putih);
              margin: 0 10px;
              border-radius: 5px;
            }
            .video-carousel-indicator li.active{
              background: var(--birumuda) 
            }`}
        </style>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          {slides}
          <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
          <CarouselControl direction="next" directionText=" " onClickHandler={next} />
        </Carousel>
      </div>
      <CarouselIndicators className="video-carousel-indicator" items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
    </div>
  );
}

const YoutubeEmbed = ({ src }) => (
  <div>
    <style>{`
      .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
      }

      .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    `}</style>
    <div className="video-responsive">
      <iframe 
      width="853" 
      height="480" 
      src={src} 
      title="YouTube video player" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen />
    </div>
  </div>
);

export default ImagesCarousel;
export { YoutubeEmbed };