import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Search from '../../components/Search/Search';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

//=== dummy
let users = [
	{id:0, imgSrc:null, progress:90, name:'Gede Sangu Gemi', level:'member premium', link:'/user-report/user0'},
	{id:1, imgSrc:null, progress:60, name:'Johandri Putra', level:'member biasa', link:'/user-report/user1'},
	{id:2, imgSrc:null, progress:25, name:'August Aorta', level:'member biasa', link:'/user-report/user2'},
]

const UserReport = () => {
	ChangeTitle({title:'Digipreneur | User Report'})
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<Link to="/" className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">User Report</h5>
			</Link>
			<div style={{background:'var(--putih)', paddingTop:'.5em'}}>
				<div className="clearfix px-3">
					<p className="float-left d-inline-block w-50">Total Member: 2676</p>
					<p className="float-left d-inline-block w-50">Premium Member: 2675</p>
				</div>
				<Search style={{margin:'0 1em 1em'}} />
				<Container><Row>
					{users.map(user=>{
						return <User key={user.id} imgSrc={user.imgSrc} progress={user.progress} name={user.name} level={user.level} link={user.link} />
					})}
				</Row></Container>
			</div>
		</Fragment>
	)
}

const User = ({ imgSrc, progress, name, level, link }) => {
	let progressColor = function(){
		if(progress>=80){
			return 'progress-success'
		}else if(progress>=60){
			return 'progress-warning'
		}else if(progress<=59){
			return 'progress-danger'
		}
	}
	return (
		<Col xs="4" className="text-center">
			<Link to={link} style={{textDecoration:'none'}}>			
				<div style={{background:'var(--abu-abu2)', borderRadius:'15px'}}>
					<img src={imgSrc || "/img/profile/Default.svg"} alt="Default.svg" style={{padding:'1em'}} />
				</div>
				<div className={progressColor()} style={{margin:'.5em 0', borderRadius:'1em', height:'1.2em', color:'var(--hitam)'}}>
					<p>{progress}%</p>
				</div>
				<p style={{fontSize:'.85em', margin:'0', color:'black', fintWeight:'500'}}>{name}</p>
				<p style={{fontSize:'.8em', margin:'0', color:'var(--abu-abu)'}}>{level}</p>
			</Link>
		</Col>
	)
}

export default UserReport;