import { useContext, useEffect } from 'react';
import { NavContext } from '../../App.js';
import { Container, Row, Col } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const ProductContent = () => {
	let { dispatch } = useContext(NavContext);

	ChangeTitle({title:'Digipreneur | Judul Produk konten'})
	useEffect(()=>{
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
		// eslint-disable-next-line 
	},[])
	return (
		<div style={{background:'var(--putih)'}}>
			<Container fluid='sm' className='p-0'>
				<div style={{borderRadius:'2em', overflow:'hidden', width:"100%"}}>
					<img src="/img/products/image.png" alt="productdummy.png" width='100%' />
				</div>
				<Row style={{padding:'1em', fontWeight:'bold', margin:'0'}}>
					<Col xs="8">Tas Selempang Army 903 tactical</Col>
					<Col xs="4">Rp. 299.000</Col>
				</Row>
				<hr className="m-0"/>
				<div style={{padding:'1em', textAlign:'justify', fontWeight:'500'}}>
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam illo, assumenda recusandae sint maxime veniam, et eius accusantium harum, molestiae consequuntur labore iusto impedit ut doloremque nesciunt vel, ex numquam.
				</div>
			</Container>
			<div style={{height:'100px'}}></div>
			<button className="fix-btn-bottom" style={{background:'var(--hijau-muda)', maxWidth: '600px'}}>BELI</button>
		</div>
	)
}

export default ProductContent;

