import { Fragment } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ isActive, onClick }) => {
	return(
		<Fragment> 
		<div className={ isActive? "fullscreen-blur opened" : "fullscreen-blur" }  onClick={ onClick }>
	    </div>
		<div className={ isActive? "sidebar opened" : "sidebar" }>
			<img src="/img/LOGO-IMG.png" alt="digipreneur-logo"/>
	        <hr/>
	    	<Nav vertical>
		        <NavItem>
		          <Link style={{textDecoration:'none'}} to="/">Beranda</Link>
		          <hr/>
		        </NavItem>
		        <NavItem>
		          <Link style={{textDecoration:'none'}} to="/profile">Profil</Link>
		          <hr/>
		        </NavItem>
		        <NavItem>
		          <Link style={{textDecoration:'none'}} to="/user-report" onClick={ onClick }>User Report</Link>
		          <hr/>
		        </NavItem>
		        <NavItem>
		          <Link style={{textDecoration:'none'}} to="#">Belajar</Link>
			      <hr/>
		        </NavItem>
		        <NavItem>
		          <Link style={{textDecoration:'none'}} to="/splash-screen" onClick={ onClick }>LOG OUT</Link>
			      <hr/>
		        </NavItem>
	      	</Nav>
	      	<div className="copyright">
		      	<p>digipreneur@2021</p>
	      	</div>
	    </div>
	    </Fragment>
	)
}

export default Sidebar;