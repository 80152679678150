import { useContext, useEffect, Fragment } from 'react';
import { NavContext } from '../../App.js';
import { NavLink } from 'react-router-dom';
import { YoutubeEmbed } from '../VideoCarousel/VideoCarousel';
import ChangeTitle from '../ChangeTitle/ChangeTitle';

const ContentList = ({match}) => {
// ========Dummy Contents
	let dummyDigitalMarketing = [
		{ to:match.url+'/the-digitalmarketing-content-1', title:'Tonton Video Marketing 1', checked:true },
		{ to:match.url+'/the-digitalmarketing-content-2', title:'Tonton Video Marketing 2', checked:true },
		{ to:match.url+'/the-digitalmarketing-content-3', title:'Tonton Video Marketing 3', checked:false },
	]
	let dummyMandiriDanBerprestasi = [
		{ to:match.url+'/the-mandiridanberprestasi-content-1', title:'Menjadi Pemimpin Yang Diteladani-part1', checked:'hide' },
		{ to:match.url+'/the-mandiridanberprestasi-content-2', title:'Menjadi Pemimpin Yang Diteladani-part2', checked:'hide' },
		{ to:match.url+'/the-mandiridanberprestasi-content-3', title:'Menjadi Pemimpin Yang Diteladani-part3', checked:'hide' },
	]
	let dummyInfoDigipreneur = [
		{ to:match.url+'/the-gettingstarted-content-1', title:'Batch #4 Digipreneur Dibuka', checked:'hide' },
		{ to:match.url+'/the-gettingstarted-content-2', title:'Batch #5 Digipreneur Dibuka', checked:'hide' },
		{ to:match.url+'/the-gettingstarted-content-3', title:'Batch #6 Digipreneur Dibuka', checked:'hide' },
		{ to:match.url+'/the-gettingstarted-content-4', title:'Batch #7 Digipreneur Dibuka', checked:'hide' },
	]
	let dummyGettingStarted = [
		{ to:match.url+'/the-gettingstarted-content-1', title:'User', checked:true },
		{ to:match.url+'/the-gettingstarted-content-2', title:'Seller', checked:false },
		{ to:match.url+'/the-gettingstarted-content-3', title:'Network Builder', checked:true },
		{ to:match.url+'/the-gettingstarted-content-4', title:'Network Leaders', checked:false },
	]
	let dummyContents = []
	//== List Konten DigitalMarketing
	if(['organik-marketing','wa-marketing','fb-marketing','ig-marketing','konten-marketing','facebook-ads','program-30-hari'].includes(match.params.contentGroup)){
		dummyContents = dummyDigitalMarketing
	}

	//== List Konten MandiriDanBerprestasi
	if(['mind-mapping','public-speaking','info-seputar-mahasiswa','tips-mandiri-dan-berprestasi'].includes(match.params.contentGroup)){
		dummyContents = dummyMandiriDanBerprestasi
	}

	//== List Konten InfoDigipreneur
	if(match.params.contentGroup==='info-digipreneur'){
		dummyContents = dummyInfoDigipreneur
	}

	//== List Konten GettingStarted
	if(match.params.contentGroup==='getting-started'){
		dummyContents = dummyGettingStarted
	}
// ========End Dummy Contents
	let title = match.params.contentGroup.split('-')
	title = title.map(a=>a[0].toUpperCase()+a.slice(1))
	title = title.join(' ')
	ChangeTitle({title:'Digipreneur | '+title})
	return (
		<Fragment>
			{/* Back to */}
			<NavLink to={"/"+match.path.split('/')[1]} className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)', textTransform:'capitalize'}} className="float-left d-inline-block mb-0">{match.params.contentGroup.replaceAll('-',' ')}</h5>
			</NavLink>
			{/* Content List */}
			<div style={{background:'var(--putih)'}} className="pt-3">
				{dummyContents.map(content=>{
					return <ContentLink key={content.to} to={content.to} title={content.title} checked={content.checked} />
				})}
			</div>
		</Fragment>
	)
}
export default ContentList;

export const ContentLink = ({to, title, checked}) => {
	let style = {
		boxShadow:'0 4px 4px 0 var(--abu-abu)',
		margin:'10px 20px',
		padding:'10px 20px',
		borderRadius: '10px',
	}
	return (
		<div style={style}>
			{ //jika checked==hide maka sembunyikan => untuk listkonten yg tdk ada checkbox
				(checked==='hide')? null: <input className='m-3' type="checkbox" checked={checked} readOnly/> 
			} 
			<NavLink style={{textDecoration:'none', color:'var(--hitam)'}} to={to}>{title}</NavLink>
		</div>
	)
}

const TheContent = ({history}) => {
	let { dispatch } = useContext(NavContext);
	useEffect(()=>{
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
			// eslint-disable-next-line 
		}, [])
	ChangeTitle({title:'Digipreneur | Judul Konten'})
	return (
		<div style={{background:'var(--putih)'}} className="pt-5">
			<p className="text-center">Video Marketing</p>
			<div className="m-4">
				<YoutubeEmbed src="https://www.youtube.com/embed/Ao3XJ-UDdzI" />
			</div>
			<p className="mx-4 text-justify">Lorem ipsum dolor, sit amet, consectetur adipisicing elit. Aut, provident sit rerum, deleniti error cumque obcaecati ad laborum aspernatur unde adipisci, atque aperiam expedita fuga id veniam praesentium repellat accusantium.</p>
			<button onClick={history.goBack} className="fix-btn-bottom text-center" style={{background:'var(--birutua)', textDecoration:'none'}}>Tutup</button>
		</div>
	)
}
export { TheContent }