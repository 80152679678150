import { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const MandiriDanBerprestasi = () => {
	ChangeTitle({title:'Digipreneur | Mandiri Dan Berprestasi'})
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<NavLink to="/" className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">Mandiri & Berprestasi</h5>
			</NavLink>
			{/* Image Banner */}
			<div className="text-center" style={{background:'var(--putih)', borderRadius:'0 0 30px 30px'}}>
				<img className="mt-4" width="80%" src="/img/dashboard/menuitem/Its-Friday-rafiki.svg" alt="Social-strategy-bro.svg" />
				<p className="text-center pb-3"><b>Tips Dan Pengetahuan Seputar Mahasiswa</b></p>
			</div>
			{/* Menu List */}
			<Container>
				<Row>
					<MenuList to="/mandiri-dan-berprestasi/mind-mapping" text="MIND MAPPING" bgColor="#FFAB28" />
					<MenuList to="/mandiri-dan-berprestasi/public-speaking" text="PUBLIC SPEAKING" bgColor="#E0E411" />
					<MenuList to="/mandiri-dan-berprestasi/info-seputar-mahasiswa" text="INFO SEPUTAR MAHASISWA" bgColor="#12E272" />
					<MenuList to="/mandiri-dan-berprestasi/tips-mandiri-dan-berprestasi" text="TIPS MANDIRI DAN BERPRESTASI" bgColor="#FF5652" />
				</Row>
			</Container>
			{/* Space */}
			<div style={{height:'100px'}}></div>
		</Fragment>
	)
}

let MenuList = ({to, text, bgColor}) => {
	return (
		<Col xs='6'><Link to={to} style={{color:'var(--hitam)', textDecoration:'none'}}>
			<div style={{borderRadius:'20px', padding:'2em 0', margin:'.5em', background:bgColor, minHeight:'9.2em', display:'flex', justifyContent:'center'}}>
				<h4 className="text-center my-auto">{text}</h4>
			</div>
		</Link></Col>
	)
}

export default MandiriDanBerprestasi;