
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

const items = [
  {
    id: 1,
    src: '/img/dashboard/carousel/image1.jpg',
    altText: 'image1.jpg'
  },
  {
    id: 2,
    src: '/img/dashboard/carousel/image2.jpg',
    altText: 'image2.jpg'
  },
  {
    id: 3,
    src: '/img/dashboard/carousel/image3.jpg',
    altText: 'image3.jpg'
  },
  {
    id: 4,
    src: '/img/dashboard/carousel/image4.jpg',
    altText: 'image4.jpg'
  },
  {
    id: 5,
    src: '/img/dashboard/carousel/image5.jpg',
    altText: 'image5.jpg'
  }
];


const ImagesCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <img src={item.src} alt={item.altText} width="100%"/>
      </CarouselItem>
    );
  });

  return (
    <div className={props.className}>
      <style>
        {`.carousel-indicator li{
            width: 10px;
            height: 10px;
            background: var(--putih);
            margin: 0 10px;
            border-radius: 5px;
          }
          .carousel-indicator li.active{
            background: var(--birumuda) 
          }`}
      </style>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators className="carousel-indicator" items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
        <CarouselControl direction="next" directionText=" " onClickHandler={next} />
      </Carousel>
    </div>
  );
}

export default ImagesCarousel;