import { createContext, useReducer, useState, Fragment } from 'react';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';

import { BarsIcon, HomeIcon, ProductIcon, FAQIcon, ProfileIcon } from './components/ImageSvg/ImageSvg';
import Sidebar from './components/Sidebar/Sidebar';
import ContentList, { TheContent } from './components/Content/Content';

import Dashboard from './pages/Dashboard/Dashboard';
import DigitalMarketing from './pages/DigitalMarketing/DigitalMarketing';
import MandiriDanBerprestasi from './pages/MandiriDanBerprestasi/MandiriDanBerprestasi';
import MentoringProgram from './pages/MentoringProgram/MentoringProgram';
import Login from './pages/Auth/Login';
import SignUp from './pages/Auth/SignUp';

import ProductKnowledge from './pages/Product/ProductKnowledge';
import ProductContent from './pages/Product/ProductContent';
import FAQ from './pages/FAQ/FAQ';
import Profile from './pages/Profile/Profile';

//sidebar
import SplashScreen from './pages/SplashScreen/SplashScreen';
import UserReport from './pages/UserReport/UserReport';
import UserReportPerUser from './pages/UserReport/UserReportPerUser';

// belum masuk navigasi
import AksesTaskPremium from './pages/AksesTaskPremium/AksesTaskPremium';

import './App.css';

export const NavContext = createContext()

const initialState = {
  navAtas: true,
  navBawah: true,
}

const reducer = (state, action) => {
  switch(action.type) {
    case "TOP_NAV_TOGGLE":
      return {
        ...state,
        navAtas: !state.navAtas
      }
    case "BOTTOM_NAV_TOGGLE":
      return {
        ...state,
        navBawah: !state.navBawah
      }
    default:
      return state
  }
}

const App = () => {
  const [ state, dispatch ] = useReducer(reducer, initialState)
  const [ isActiveBars, setIsActiveBars ] = useState(false);

  const toggleBar = () => {
    setIsActiveBars( !isActiveBars )
  }

  return (
    <div id="app" style={{maxWidth:'600px', margin:'0 auto'}}>
    <BrowserRouter>
      <NavContext.Provider value={{state, dispatch}}>

      { (state.navAtas)? <TopNav handleBars={ toggleBar } />: null }
      { (state.navBawah)? <BottomNav />: null }
      <Sidebar onClick={ toggleBar } isActive={ isActiveBars } />

      <Switch>
        {/* list menu dashboard */}
          {/* Menuitem Digital Marketing */}
          <Route path="/digital-marketing" exact component={DigitalMarketing}/>
          <Route path="/digital-marketing/:contentGroup" exact component={ContentList}/>
          <Route path="/digital-marketing/:contentGroup/:theContent" exact component={TheContent}/>
          {/* Menuitem Mandiri dan Berprestasi  */}
          <Route path="/mandiri-dan-berprestasi" exact component={MandiriDanBerprestasi}/>
          <Route path="/mandiri-dan-berprestasi/:contentGroup" exact component={ContentList}/>
          <Route path="/mandiri-dan-berprestasi/:contentGroup/:theContent" exact component={TheContent}/>
          {/* Menuitem Mentoring Program */}
          <Route path="/mentoring-program" exact component={MentoringProgram}/>
          {/* Menuitem Info Digipreneur */}
          <Route path="/info/:contentGroup" exact component={ContentList}/>
          <Route path="/info/:contentGroup/:theContent" exact component={TheContent}/>

        {/* Tombol Getting Started */}
        <Route path="/dashboard/:contentGroup" exact component={ContentList}/>
        <Route path="/dashboard/:contentGroup/:theContent" exact component={TheContent}/>

        {/* navigasi bawah */}
        <Route path="/products" exact component={ProductKnowledge}/>
        <Route path="/products/:theProduct" exact component={ProductContent}/>
        <Route path="/profile" exact component={Profile}/>
        <Route path="/faq" exact component={FAQ}/>
        <Route path="/" exact component={Dashboard}/>

        {/* navigasi sidebar */}
        <Route path="/user-report" exact component={UserReport}/>
        <Route path="/user-report/:id" exact component={UserReportPerUser}/>
        <Route path="/splash-screen" exact component={SplashScreen}/>
          {/* splash screen */}
          <Route path="/login" exact component={Login}/>
          <Route path="/signup" exact component={SignUp}/>

        {/* Belum Masuk Navigasi*/}
          {/* akses task premium */}
          <Route path="/premium-access" exact component={AksesTaskPremium}/>
      </Switch>

      </NavContext.Provider>
    </BrowserRouter>
    </div>
  );
}
export default App;

const TopNav = ({ handleBars }) => {
  return (
    <Fragment>
      <header style={{background:'var(--putih)', position:'fixed', zIndex:'100', right:'0', left:'0', top:'0', maxWidth:'600px', margin:'0 auto'}}>
        <div className="header">
          <img src="/img/LOGO-IMG.png" width='150' alt="logo-digipreneur" />
          <BarsIcon fill="#333" width='20' onClick={ handleBars }/>
        </div>
      </header>
      <div style={{height:'60px'}}></div>
    </Fragment>
  )
}

const BottomNav = () => {
  return (
    <nav style={{zIndex:'100', maxWidth:'600px', margin:'0 auto'}}>
      <div className="menus-wrapper row">
        <NavLink to="/" activeClassName="active_" exact className="col-3">
          <div className="menu-wrapper">
            <HomeIcon width="25"/>
            <p>Home</p>
          </div>
        </NavLink>
        <NavLink to="/products" activeClassName="active_" className="col-3">
          <div className="menu-wrapper">
            <ProductIcon width="27"/>
            <p>Product</p>
          </div>
        </NavLink>
        <NavLink to="/faq" activeClassName="active_" className="col-3">
          <div className="menu-wrapper">
            <FAQIcon width="23"/>
            <p>FAQ</p>
          </div>
        </NavLink>
        <NavLink to="/profile" activeClassName="active_" className="col-3">
          <div className="menu-wrapper">
            <ProfileIcon width="23"/>
            <p>Profile</p>
          </div>
        </NavLink>
      </div>
    </nav>
  )
}

