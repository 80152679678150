import { useEffect } from 'react';

const ChangeTitle = ({title}) => {
	useEffect(()=>{
		document.title = title
		return ()=>{
			document.title = 'Digipreneur'
		}
	})
}
export default ChangeTitle;