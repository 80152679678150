import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import { NavContext } from '../../App.js';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';
const Login = () => {
	ChangeTitle({title:'Digipreneur | Login'})

	let { dispatch } = useContext(NavContext);
	let { formData, setFormData } = useState({ email:'', password:'' });

	let handleData = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}

	useEffect(()=>{
		dispatch({type:'TOP_NAV_TOGGLE'});
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'TOP_NAV_TOGGLE'});
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
		// eslint-disable-next-line 
	},[])

	return (
		<div style={{background:'var(--putih)'}}>
			<Container>
				<p style={{margin:'4em 0 2em', fontWeight:'500' }}>Silakan masuk dengan email yang sudah terdaftar</p>
				<Input style={{background:'var(--abu-abu3)', borderRadius:'15px', margin:'1em 0'}} type="email" name="email" onChange={e=>handleData(e)} placeholder="Email" />
				<Input style={{background:'var(--abu-abu3)', borderRadius:'15px', margin:'1em 0'}} type="password" name="password" onChange={e=>handleData(e)} placeholder="Password" />
				<button className="btn w-100" style={{fontWeight:'bold', color:'var(--putih)', padding:'.8em', background:'var(--ungu)', borderRadius:'15px'}}>LOGIN</button>
				<p style={{fontSize:'.8em', margin:'1em .3em', color:'var(--abu-abu)'}}>Lupa password anda? <Link to="/forgot" style={{color:'var(--kuning)'}}>Reset Disini</Link></p>
				<p style={{color:'var(--abu-abu)', margin:'2em .3em .3em'}}>atau kalau belum punya akun klik</p>
				<Link to="/signup" className="btn w-100" style={{fontWeight:'bold', padding:'.8em', border:'solid 1px var(--kuning)', color:'var(--kuning)', borderRadius:'15px'}}>CREATE ACOOUNT</Link>
			</Container>
		</div>
	)
}

export default Login;