import { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ImagesCarousel from '../../components/ImagesCarousel/ImagesCarousel';
import VideoCarousel from '../../components/VideoCarousel/VideoCarousel';
import MenuItem from '../../components/MenuItem/MenuItem';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';
const Dashboard = () => {
	ChangeTitle({title:'Digipreneur | Dashboard'})
	return (
		<Fragment>
			{/* Css */}
			<Style/>
			{/* Carousel */}
			<ImagesCarousel className="imgCarousel"/>
			{/* Motto */}
			<p className="motto">Bangun Masa Depan Anda Menjadi Lebih Baik Bersama Digipreneur Indonesia</p>
			{/* Menu Items */}
			<Container>
				<Row>
					<MenuItem imgSrc="/img/dashboard/menuitem/Social-strategy-bro.svg" to="/digital-marketing" text="Digital Marketing" />
					<MenuItem imgSrc="/img/dashboard/menuitem/Its-Friday-rafiki.svg" to="/mandiri-dan-berprestasi" text="Mandiri & Berprestasi" />
					<MenuItem imgSrc="/img/dashboard/menuitem/Personal-Trainer-rafiki.svg" to="/mentoring-program" text="Mentoring Program" />
					<MenuItem imgSrc="/img/dashboard/menuitem/Data-rafiki.svg" to="/info/info-digipreneur" text="Info Digipreneur" />
				</Row>
			</Container>
			{/* Menu Items */}
			<NavLink to="/dashboard/getting-started" style={{textDecoration:'none', color:'var(--hitam)'}}>
				<h3 className="cta">GETTING STARTED!!!</h3>
			</NavLink>
			{/* Menu Items */}
			<VideoCarousel className="videoCarousel" />
			{/* Medsos CTA */}
			<div style={{marginTop:'40px', textAlign:'center', background:'var(--putih)', borderTop:'3px solid var(--hitam)', padding:'20px'}}>
				<p><b>Ayo join Grup<br/>Komunitas Digipreneur</b></p>
				<Container>
					<Row>
						<MedsosCTA src="/img/dashboard/icon/Whatsapp.png" />
						<MedsosCTA src="/img/dashboard/icon/Facebook.png" />
						<MedsosCTA src="/img/dashboard/icon/Telegram.png" />
					</Row>
				</Container>
			</div>
			<div style={{height:'45px'}}></div>
		</Fragment>
	)
}

const MedsosCTA = ({src}) => {
	return (
		<Col xs="4">
			<div style={{boxShadow:'var(--abu-abu) 0px 4px 3px', borderRadius:'30px', background:'var(--putih)', border:'solid 1px var(--abu-abu)', padding:'1.4em'}}>
				<img src={src} alt={src} width="100%" style={{margin:'0 auto'}} />
			</div>
		</Col>
	)
}

const Style = () => {
	return (
		<style>
			{`
				.imgCarousel{
					margin: 10px 0;
					border-radius: 10px;
					overflow: hidden;
				}
				.motto{
					background: var(--birutua2);
					border-radius: 10px;
					color: var(--putih);
					margin: 20px 10px;
					padding: 10px;
					text-align: center;
				}
				.cta{
					margin: 10px;
					text-align: center;
					color: var(--putih);
					background: var(--birutua2);
					padding: 15px;
					border-radius: 20px;
					border: solid 1px var(--kuning-hijau);
				}
				.videoCarousel{
					margin: 10px 15px;
					border-radius: 10px;
					overflow: hidden;
				}
			`}
		</style>
	)
}
export default Dashboard;