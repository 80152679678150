import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

//==dummy products
let products = [
	{id: 0, imgSrc:'/img/products/image.png', title:'Tas Selempang Army 903 tactical 01', link:'/products/products1'},
	{id: 1, imgSrc:'/img/products/image.png', title:'Tas Selempang Army 903 tactical 02', link:'/products/products2'},
	{id: 2, imgSrc:'/img/products/image.png', title:'Tas Selempang Army 903 tactical 03', link:'/products/products3'},
	{id: 3, imgSrc:'/img/products/image.png', title:'Tas Selempang Army 903 tactical 04', link:'/products/products4'}
]

const ProductKnowledge = () => {
	ChangeTitle({title:'Digipreneur | Products'})
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<Link to="/" className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">PRODUCTS</h5>
			</Link>
			<Container style={{background:'var(--putih)', padding:'1em'}}>
				<Row>
					{products.map(product=>{
						return <Product src={product.imgSrc} text={product.title} link={product.link} key={product.id} />
					})}
				</Row>
			</Container>
		</Fragment>
	)
}

const Product = ({ src, text, link }) => {
	return (
		<Col xs="6" className="text-center">
			<Link to={link} style={{textDecoration:'none', color:'black'}}>
				<div style={{borderRadius:'10px', overflow:'hidden', width:'90%', margin:'auto'}}>
					<img src={src} alt={src} width="100%"/>
				</div>
				<p>{text}</p>
			</Link>
		</Col>
	)
}

export default ProductKnowledge;