import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

// Dummy FAQ
let faqlist = [
	{
		id: 1,
		question: 'Soal 1',
		answer: 'Jawaban 1, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 2,
		question: 'Soal 2',
		answer: 'Jawaban 2, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 3,
		question: 'Soal 3',
		answer: 'Jawaban 3, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 4,
		question: 'Soal 4',
		answer: 'Jawaban 4, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 5,
		question: 'Soal 5',
		answer: 'Jawaban 5, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 6,
		question: 'Soal 6',
		answer: 'Jawaban 6, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},{
		id: 7,
		question: 'Soal 7',
		answer: 'Jawaban 7, Lorem ipsum dolor sit amet consectetur adipisicing, elit. Iusto placeat ea similique voluptatibus sapiente maxime, iure quia, cumque nam, tenetur libero, ad quidem ut veniam quibusdam ex ab quis ullam!'
	},
]

const FAQ = () => {
	ChangeTitle({title:'Digipreneur | FAQ'})
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<NavLink to='/'>
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">FAQ</h5>
			</NavLink>
			{/* FAQ list */}
			<div style={{background:'var(--putih)', marginTop:'-1.5em'}}>
				<div className="m-4 pt-3">
					{faqlist.map(faq=>{
						return <Accordion key={faq.id} id={faq.id} question={faq.question} answer={faq.answer} />
					})}
				</div>
			</div>
		</Fragment>
	)
}
export default FAQ;

const Accordion = ({question, answer}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  let btnStyle = {
  	width:'100%',
  	borderRadius:'.5em',
  	boxShadow: '0 4px 4px 0 var(--abu-abu)',
  	margin:'.3em 0',
  	background:'var(--putih)'
  }
  let bodyStyle = {
  	borderRadius:'0 0 10px 10px',
  	padding:'.5em 1em 1em 1em',
  	textAlign:'justify',
  	background: 'var(--abu-abu2)',
  	marginTop:'-.6em'
	}
  return (
    <div>
      <button className="btn" onClick={toggle} style={btnStyle}>{question}</button>
      <Collapse isOpen={isOpen}>
        <div style={bodyStyle}>{answer}</div>
      </Collapse>
    </div>
  );
}