import { useContext, useEffect } from 'react';
import { NavContext } from '../../App.js';
import { Link } from 'react-router-dom';
import ImagesCarousel from '../../components/ImagesCarousel/ImagesCarousel';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const SplashScreen = () => {
	let { dispatch } = useContext(NavContext);

	ChangeTitle({title:'Digipreneur | SplashScreen'})
	useEffect(()=>{
		dispatch({type:'TOP_NAV_TOGGLE'});
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'TOP_NAV_TOGGLE'});
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
		// eslint-disable-next-line 
	},[])

	return (
		<div style={{background:'var(--putih)', textAlign:'center'}}>
	        <img src="/img/LOGO-IMG.png" width='150' alt="logo-digipreneur" style={{margin:'1em 0'}} />
	        <ImagesCarousel />
	        <div style={{borderRadius:'10px 10px 0 0', padding:'7.5em 3em', marginTop:'-.5em', background:'var(--putih)', position:'relative'}}>
	        	<p style={{fontWeight:'bold', letterSpacing:'3%', fontSize:'1.3em', marginBottom:'2.5em'}}>SEBUAH LANGKAH MENUJU PERUBAHAN BESAR</p>
	        	<Link to="/login" className='btn w-100' style={{background:'var(--ungu)', color:'var(--putih)', padding:'.5em', marginBottom:'1em', textDecoration:'none'}}>Masuk Aplikasi</Link>
	        	<Link to="/signup" className='btn w-100' style={{background:'var(--kuning)', color:'var(--putih)', padding:'.5em', marginBottom:'1em', textDecoration:'none'}}>Pendaftaran</Link>
	        </div>
	        <p style={{fontStyle:'italic'}}>digipreneur@ 2021</p>
		</div>
	)
}

export default SplashScreen;