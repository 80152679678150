import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import MenuItem from '../../components/MenuItem/MenuItem';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const DigitalMarketing = () => {
	ChangeTitle({title:'Digipreneur | Digital Marketing'})
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<NavLink to="/" className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">Digital Marketing</h5>
			</NavLink>
			{/* Image Banner */}
			<div className="text-center" style={{background:'var(--putih)', borderRadius:'0 0 30px 30px'}}>
				<img className="mt-4" width="80%" src="/img/dashboard/menuitem/Social-strategy-bro.svg" alt="Social-strategy-bro.svg" />
			</div>
			{/* MenuItems */}
			<Container className="mt-4">
				<Row>
					<MenuItem imgSrc="/img/digitalmarketing/image 8.png" to="/digital-marketing/organik-marketing" text="Organik Marketing" />
					<MenuItem imgSrc="/img/digitalmarketing/image 9.png" to="/digital-marketing/wa-marketing" text="WA Marketing" />
					<MenuItem imgSrc="/img/digitalmarketing/image 12.png" to="/digital-marketing/fb-marketing" text="FB Marketing" />
					<MenuItem imgSrc="/img/digitalmarketing/image 13.png" to="/digital-marketing/ig-marketing" text="IG Marketing" />
					<MenuItem imgSrc="/img/digitalmarketing/image 14.png" to="/digital-marketing/konten-marketing" text="Konten Marketing" />
					<MenuItem imgSrc="/img/digitalmarketing/image 15.png" to="/digital-marketing/facebook-ads" text="Facebook Ads" />
					<MenuItem imgSrc="/img/digitalmarketing/image 16.png" to="/digital-marketing/program-30-hari" text="Program 30 Hari" />
				</Row>
			</Container>
		</Fragment>
	)
}

export default DigitalMarketing;