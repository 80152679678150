import { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const MentoringProgram = () => {
	return (
		<Fragment>
			<img src="/img/mentoringprogram/image 17.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 18.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 19.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 20.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 21.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 22.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 23.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 24.png" alt="mentoring program.png" />
			<CTA to="#" text="Daftar Sekarang" />
			<img src="/img/mentoringprogram/image 25.png" alt="mentoring program.png" />
			<CTA to="#" text="Saya Mau Gabung" />
			<img src="/img/mentoringprogram/image 26.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 27.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 28.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 29.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 30.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 31.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 32.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 33.png" alt="mentoring program.png" />
			<img src="/img/mentoringprogram/image 34.png" alt="mentoring program.png" />
			<CTA to="#" text="Saya Mau Gabung" />
			<img src="/img/mentoringprogram/image 35.png" alt="mentoring program.png" />
		</Fragment>
	)
}

const CTA = ({to, text}) => {
	ChangeTitle({title:'Digipreneur | Mentoring Program'})
	return (
		<Container className="py-3" style={{background:'var(--putih)'}}><Row>
			<Col xs={{size:10, offset:1}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
				<Link className="w-100 btn btn-success p-2" style={{background:'var(--hijau-muda)'}} to={to}>{text}</Link>
			</Col>
		</Row></Container>
	)
}

export default MentoringProgram;