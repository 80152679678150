import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const Profile = () => {
	const [ form, setForm ] = useState({
		nama:'', noWa:'', email:'', alamat:''
	})
	const [ user, setUser ] = useState({
		nama:'', level:'', noWa:'', email:'', alamat:''
	})

	ChangeTitle({title:'Digipreneur | Profile'})
	useEffect(()=>{
		setUser({
			nama: 'Budi Hartanto',
			level: 'member premium',
			noWa: '08123456789',
			email: 'budihartanto@digipreneur.id',
			alamat: 'Jl. Kalimantan no.82, Kec. Kabuwaru, Kota Jayapura'
		})
	}, [])

	const handleForm = e => {
		setForm({
			[e.target.name]:e.target.value
		})
	}

	const submitForm = () => {
		console.log(form)
	}

	let inputStyle = {background:'var(--abu-abu3)', margin:'0 0 .6em', borderRadius:'10px'}
	return (
		<Fragment>
			{/* Back to Dashboard */}
			<Link to="/" className="clearfix">
				<img src="/img/arrow-left.svg" alt="arrow-left.svg" className="float-left m-3"/>
				<h5 style={{color:'var(--putih)'}} className="float-left d-inline-block mb-0">Digital Marketing</h5>
			</Link>

			{/* Profile */}
			<div style={{background:'var(--putih)'}}>
				<div style={{textAlign:'center', padding:'2em'}}>
					<div style={{borderRadius:'10px', background:'var(--abu-abu2)', margin:'0 auto 1em', width:'fit-content'}}>
						<img style={{margin:'1.2em'}} src="/img/profile/Default.svg" alt="Default.svg" />
					</div>
					<h5 className="m-0">{user.nama}</h5>
					<p style={{color:'var(--abu-abu)'}}>{user.level}</p>
				</div>

				{/* Form profil */}
				<Container>
					<Row>
						<Form>
							<FormGroup row>
								<Label xs={3} for="nama">Nama</Label>
								<Col xs={9}>
									<Input style={inputStyle} type="text" name="nama" id="nama" defaultValue={user.nama} onChange={e=>handleForm(e)} />
								</Col>
								<hr/>
							</FormGroup>
							<FormGroup row>
								<Label xs={3} for="noWa">No. Wa</Label>
								<Col xs={9}>
									<Input style={inputStyle} type="text" name="noWa" id="noWa" defaultValue={user.noWa} onChange={e=>handleForm(e)} />
								</Col>
								<hr/>
							</FormGroup>
							<FormGroup row>
								<Label xs={3} for="email">Email</Label>
								<Col xs={9}>
									<Input style={inputStyle} type="text" name="email" id="email" defaultValue={user.email} onChange={e=>handleForm(e)} />
								</Col>
								<hr/>
							</FormGroup>
							<FormGroup row>
								<Label xs={3} for="alamat">Alamat</Label>
								<Col xs={9}>
									<Input style={inputStyle} type="textarea" name="alamat" id="alamat" defaultValue={user.alamat} onChange={e=>handleForm(e)} />
								</Col>
								<hr/>
							</FormGroup>
						</Form>
					</Row>
					<button onClick={submitForm} className="btn w-100" style={{background:'var(--birumuda)', borderRadius:'10px', color:'var(--putih)', padding:'.6em'}}>EDIT PROFILE</button>
					<Link to="/logout" className="btn w-100 mt-2" style={{background:'var(--salmon)', borderRadius:'10px', color:'var(--putih)', padding:'.6em'}}>LOG OUT</Link>
				</Container>
			{/* space */}
			<div style={{height:'100px'}}></div>
			</div>
		</Fragment>
	)
}

export default Profile;