import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import { NavContext } from '../../App.js';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';

const SignUp = () => {
	ChangeTitle({title:'Digipreneur | SugnUp'})

	let { dispatch } = useContext(NavContext);
	let { formData, setFormData } = useState({ email:'', password:'' });

	let handleData = e => {
		setFormData({
			...formData,
			[e.target.name]:e.target.value
		})
	}

	useEffect(()=>{
		dispatch({type:'TOP_NAV_TOGGLE'});
		dispatch({type:'BOTTOM_NAV_TOGGLE'});
			return () => {
				dispatch({type:'TOP_NAV_TOGGLE'});
				dispatch({type:'BOTTOM_NAV_TOGGLE'});
			}
		// eslint-disable-next-line 
	},[])

	let inputStyle = {background:'var(--abu-abu3)', borderRadius:'15px', margin:'1em 0'}
	return (
		<div style={{background:'var(--putih)'}}>
			<Container>
				<p style={{margin:'4em 0 2em', fontWeight:'500' }}>Yuk lengkapi form ini untuk mendaftar</p>
				<Input style={inputStyle} type="text" name="fullname" onChange={e=>handleData(e)} placeholder="Nama Lengkap" />
				<Input style={inputStyle} type="email" name="email" onChange={e=>handleData(e)} placeholder="Email" />
				<Input style={inputStyle} type="password" name="password" onChange={e=>handleData(e)} placeholder="Password" />
				<Input style={inputStyle} type="password" name="cPassword" onChange={e=>handleData(e)} placeholder="isikan ulang password" />
				<Input style={inputStyle} type="text" name="referealCode" onChange={e=>handleData(e)} placeholder="isikan kode referal" />
				<button className="btn w-100" style={{fontWeight:'bold', color:'var(--putih)', padding:'.8em', background:'var(--kuning)', borderRadius:'15px', margin:'1.5em 0'}}>DAFTAR</button>
				<p style={{fontSize:'.8em', margin:'0 .3em', color:'var(--abu-abu)'}}>Sudah punya akun? <Link to="/login" style={{color:'var(--ungu)'}}>Login Disini</Link></p>
			</Container>
		</div>
	)
}

export default SignUp;