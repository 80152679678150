import { Search as SearchSVG } from '../ImageSvg/ImageSvg';
import './Search.css';

const Search = ({ onChange, style }) => {
	return(
		<div className="search-wrapper" style={style}>
			<div className="search">
				<SearchSVG width='20' fill="#bbb"/>
				<input type="text" placeholder="Search" onChange={onChange} />
			</div>
		</div>
	)
}

export default Search;