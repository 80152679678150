import { Form, FormGroup, Input } from 'reactstrap';
import ChangeTitle from '../../components/ChangeTitle/ChangeTitle';
const AksesTaskPremium = () => {
	ChangeTitle({title:'Digipreneur | Akses Premium'})
	return (
		<div style={{padding:'2em 3em', background:'var(--putih)'}}>
			<style>{`
				.atp-input{
					border-radius: 15px;
			    background: var(--abu-abu3);
			    border: none;
			    font-size: 1em;
			    padding: 0.7em 1.3em;
				}
			`}</style>
			<p className="text-center">Kamu Harus Menjadi Member Premium Untuk akses halaman Ini</p>
			<button className="btn btn-block text-white w-100 py-2" style={{background: 'linear-gradient(209.98deg, #00B319 100.7%, #8F70FF 100.71%)'}}>DAFTAR MEMBER PREMIUM</button>
			<p className="text-center mt-5">Silakan Isikan Data Anda Disini untuk informasi lebih lanjut </p>
			<Form>
	      <FormGroup className="mt-3">
	        <Input className='atp-input' type="text" name="nama" id="nama" placeholder="Nama Lengkap" />
	      </FormGroup>
	      <FormGroup className="mt-3">
	        <Input className='atp-input' type="email" name="email" id="email" placeholder="Email" />
	      </FormGroup>
	      <FormGroup className="mt-3">
	        <Input className='atp-input' type="textarea" name="pertanyaan" id="pertanyaan" placeholder="Pertanyaan" />
	      </FormGroup>
	      <button className="btn w-100 text-white mt-3" style={{background:'var(--birutua)', borderRadius:'15px'}}>SUBMIT</button>
    	</Form>
		</div>
	)
}

export default AksesTaskPremium;